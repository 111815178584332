import { Text, Card, Flex } from "@radix-ui/themes";

export function MetricCard({ metricName, metricIcon, metric, updates }) {
  return (
    <Card style=
      {
        { 
          display: "flex",
          padding: 'var(--space-4)',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          flex: '1 0 0',
          alignSelf: 'stretch',
          height: '100%'
        }
      }
    >
      <Flex 
        display="flex" 
        direction="column" 
        align="start" 
        gap="4"
        style={{alignSelf: "stretch"}} 
      >
        <Flex
          display="flex"
          align="center"
          gap="2"
          style={{alignSelf: "stretch"}} 
        >
          {metricIcon}
          <Text gap="2" size="2" weight="medium" style={{color: 'var(--gray-11)', display: 'flex', alignItems: 'center'}}>{metricName}</Text>
        </Flex>
        <Text size="6" weight="bold">{metric}</Text>
      </Flex>
      <Text size="1" weight="regular" style={{color: 'var(--gray-11)'}}>{updates}</Text>
    </Card>
  )
}