import RouteManager from "RouteManager";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import '@radix-ui/themes/styles.css';
import { Theme } from "@radix-ui/themes";
import './index.css';

// import AuthProvider from "components/common/AuthProvider ";
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      retryDelay: 1000,
    },
  },
});

function App() {
  // const [appearance, setAppearance] = React.useState("light");
  // const handleAppearance = () => setAppearance(appearance === "light" ? "dark" : "light")

  return (
    <QueryClientProvider client={queryClient}>
      <Theme
        appearance="light" 
        accentColor="blue"
        grayColor="gray"
        panelBackground="solid"
      >
        <div className="bg-repeat">
          <RouteManager />
          <ToastContainer
            bodyClassName={() => "flex w-auto items-center"}
            //progressClassName={() => "toast-progress"}
            toastClassName={() =>
              "bg-colors-bg-natural-strong text-sm text-colors-text-natural-inverse block rounded-lg flex w-auto min-w-1 items-center py-3 px-4"
            }
            position="bottom-center"
            autoClose={10000}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            hideProgressBar
          />
        </div>
        <ReactQueryDevtools initialIsOpen={false} />
      </Theme>
    </QueryClientProvider>
  );
}

export default App;
