"use client";

import * as React from "react";
import { format, isAfter, isBefore, startOfDay } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { Cn } from "components/utils/Cn";
import { ShadButton } from "components/buttons/ShadButton";
import { Calendar } from "components/calendar/Calendar";
import { Popover, PopoverContent, PopoverTrigger,} from "components/popover/Popover";

export function DatePickerWithRange({ className, value, onDateChange, disableBeforeDate }) {
  const today = startOfDay(new Date());
  const before = startOfDay(disableBeforeDate)
  const [date, setDate] = React.useState(value || { from: null, to: null });
  React.useEffect(() => {
    if (value) {
      setDate(value);
    }
  }, [value]);

  const handleDateChange = (newDate) => {
    setDate(newDate);
    if (onDateChange) {
      onDateChange(newDate);
    }
  };

  return (
    <div className={Cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <ShadButton
            id="date"
            variant={"outline"} 
            className={Cn(
              "w-[228px] h-[32px] justify-start text-left",
              !date && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date?.from ? (
              date.to ? (
                <React.Fragment>
                  {format(date.from, "dd LLL y")} -{" "}
                  {format(date.to, "dd LLL y")}
                </React.Fragment>
              ) : (
                format(date.from, "LLL dd, y")
              )
            ) : (
              <span>Pick a date</span>
            )}
          </ShadButton>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start" size="2">
          <Calendar
            initialFocus
            mode="range"
            selected={date}
            onSelect={handleDateChange}
            numberOfMonths={2}
            disabled={(date) => isAfter(date, today) || isBefore(date, before)}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}