import React from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import ProtectedRoute from "./components/common/PrivateRoute";

// Import your components without lazy loading
import Login from "./pages/login/Login";
import Registration from "./pages/singup/Registration";
import Setup from "./pages/singup/Setup";
import Resetpassword from "./pages/resetpassword/Resetpassword";
import Changepassword from "./pages/changepassword/Changepassword";
import Dashboard from "./pages/dashboard/Dashboard";
import CreateCustomer from "./pages/createCustomer/CreateCustomer";
import Logout from "./pages/logout/Logout";
// import DashboardLayout from "./layout/dashboardLayout/DashboardLayout";
import Customers from "./pages/customers/Customers";
import Account from "./pages/account/Account";
import Locations from "./pages/locations/Locations";
import AuthProvider from "components/common/AuthProvider";
import PublicRoute from "components/common/PublicRoute";

export default function RouteManager() {
  const userRole = localStorage.getItem("user_role");

  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route element={<PublicRoute />}>
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/forgot-password" element={<Resetpassword />} />
            <Route path="/password-reset-confirm" element={<Changepassword />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/setup" element={<Setup />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<Dashboard />}> 
            {/* {element prop previously used to be DashboardLayout} */}
              <Route
                index
                element={
                  <Navigate
                    to={userRole === "admin" ? "/customers" : "/dashboard"}
                  />
                }
              />
              <Route element={<ProtectedRoute userRole="admin" />}>
                <Route path="customers" element={<Customers />} />
                <Route path="customers/:id" element={<CreateCustomer />} />
              </Route>
              <Route element={<ProtectedRoute userRole="business_owner" />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="locations" element={<Locations />} />
              </Route>

              <Route path="account" element={<Account />} />
            </Route>
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}
