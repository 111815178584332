import classNames from 'classnames';

const TRANSITIONS = {
  overlay: {
    timeout: 150,
    classNames: {
      enter: 'opacity-0 scale-75',
      enterActive: 'opacity-100 !scale-100 transition-transform transition-opacity duration-150 ease-in',
      exit: 'opacity-100',
      exitActive: '!opacity-0 transition-opacity duration-150 ease-linear'
    },
  }
};

const Tailwind = {
  multiselect: {
    root: ({ props }) => ({
      className: classNames('h-12 pl-5 pr-2 py-2 justify-center items-center gap-2 inline-flex cursor-pointer select-none bg-white border border-[#e0e0e0] dark:border-gray-800 rounded-lg shadow-sm', 
        {
          'opacity-60 select-none pointer-events-none cursor-default': props.disabled
        }
      )
    }),
    labelContainer: {
      className: 'relative bg-white/0 overflow-hidden flex flex-auto cursor-pointer items-center rounded-md h-8'
    },
    label: ({ props }) => ({
      className: classNames('grow shrink basis-0 text-[#090021] text-sm font-medium leading-tight', 
        {
          '!p-3': props.display !== 'chip' && (props.value === null || props.value === undefined),
          '!py-1.5 px-3': props.display === 'chip' && props.value !== null
        }
      )
    }),
    panel: {
      className: 'bg-white dark:bg-gray-900 text-gray-700 dark:text-white/80 border border-[#e0e0e0] dark:border-gray-800 rounded-md shadow-lg'
    },
    header: {
      className: 'h-12 pl-4 pr-4 py-2 justify-center items-center gap-2 inline-flex border-b border-[#e0e0e0]'
    },
    headerCheckboxContainer: {
      className: 'inline-flex cursor-pointer select-none align-middle relative mr-3 w-5 h-5'
    },
    headerCheckbox: {
      root: ({ props }) => ({
        className: classNames('w-4 h-4 bg-[#6e00ff] rounded-[3px] justify-center items-center flex border border-solid relative top-0.5',
          {
            'border-gray-300 dark:border-blue-900/40 bg-white dark:bg-gray-900': !props?.checked,
            'border-blue-500': props?.checked
          }
        )
      })
    },
    headerCheckboxIcon: {
      className: 'w-3 h-3 text-white'
    },
    closeButton: {
      className: 'flex items-center justify-center overflow-hidden relative w-8 h-8 text-gray-600 dark:text-white/70 border-0 bg-transparent rounded-full transition duration-200 ease-in-out hover:text-gray-800 dark:hover:text-white/80 hover:bg-gray-200 dark:hover:bg-gray-800/80 focus:outline-none'
    },
    closeIcon: {
      className: 'w-4 h-4 inline-block text-gray-600 dark:text-white/70'
    },
    wrapper: {
      className: 'max-h-[300px] overflow-auto bg-white text-gray-700 border border-[#e0e0e0] dark:border-gray-800 rounded-md shadow-md dark:bg-gray-900 dark:text-white/80'
    },
    list: {
      className: 'py-2 list-none m-0'
    },
    item: ({ context = {} }) => {
      return {
        className: classNames(
          'cursor-pointer  pl-4 pr-3 py-2 rounded-lg text-[#090021] hover:bg-[#f0f0ff] dark:text-white/80 dark:hover:bg-gray-800 flex gap-2', 
          {
            'text-gray-700 hover:text-gray-700 hover:bg-gray-200 dark:text-white/80 dark:hover:bg-gray-800': !context.focused && !context.selected,
            'bg-gray-300 text-gray-700 dark:text-white/80 dark:bg-gray-800/90 hover:text-gray-700 hover:bg-gray-200 dark:text-white/80 dark:hover:bg-gray-800': context.focused && !context.selected,
            'dark:text-white/80': context.focused && context.selected,
            'text-blue-700 dark:text-white/80': !context.focused && context.selected
          }
        ),

      };
    },
    checkboxContainer: {
      className: 'cursor-pointer select-none mr-3'
    },
    checkbox: ({ context = {} }) => {
      return {
        className: classNames(
          'w-4 h-4 bg-[#6e00ff] rounded-[3px] justify-center items-center border border-solid relative top-1', 
          {
            'border-gray-300 dark:border-blue-900/40 bg-white dark:bg-gray-900': !context.selected,
            'border-blue-500 bg-blue-500': context.selected
          }
        )
      };
    },
    checkboxIcon: {
      className: 'w-3 h-3 text-white'
    },
    filterContainer: {
      className: 'relative bg-white/0 overflow-hidden flex flex-auto cursor-pointer items-center'
    },
    filterInput: {
      className: ' shrink basis-0 w-full text-[#090021] text-sm font-normal  leading-tight pl-2 py-1 focus:outline-none rounded-md h-8'
    },
    filterIcon: {
      className: 'w-4 h-4 text-gray-600 absolute left-2'
    },
    clearIcon: {
      className: 'w-4 h-4 text-gray-600 absolute right-3 cursor-pointer transition-colors duration-200 hover:text-gray-800'
    },
    transition: TRANSITIONS.overlay
  }
};

export { Tailwind };