import React, { useState , useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { subDays } from 'date-fns';
import { Text, Flex, Separator, Badge, Tooltip } from "@radix-ui/themes";
import { CaretSortIcon, GearIcon } from '@radix-ui/react-icons';
import { LiveConnectionButton, AvatarDropdownMenu, DeviceGroupingSwitch } from "components";
import { Header } from "components";
import { PrimeReactProvider } from "primereact/api";
import { MultiSelect } from "primereact/multiselect";
import { Tailwind } from './PrimeReactProviderPt';
import { useGetOrgLocationsByUser, useGetOrgLocationsMetrics, useGetLiveConnectionStats } from "hooks";
import { DatePickerWithRange } from "components/datepicker/DatePickerWithRange";
import { LiveStatCard } from "components/card/LiveStatCard";
import { BarChartWithLine } from "components/charts/BarChartWithLine";
import { HeatmapChart } from "components/charts/HeatmapChart";
import { BarChart } from "components/charts/BarChart";
import { LineChart } from "components/charts/LineChart";
import { MetricCard } from "components/card/MetricCard";
import { ChartCard } from "components/card/ChartCard";
import { ReactComponent as AllConnectionIcon } from '../../assets/icons/all_connections.svg';
import { ReactComponent as ReturnConnectionIcon } from '../../assets/icons/return_connections.svg';
import { ReactComponent as NewConnectionIcon } from '../../assets/icons/new_connections.svg';
import { ReactComponent as AvgTimeConnectionIcon } from '../../assets/icons/avg_time_connections.svg';
import { ReactComponent as AvgHourConnectionIcon } from '../../assets/icons/avg_hour_connections.svg';
import { ReactComponent as AvgDailyConnectionIcon } from '../../assets/icons/avg_daily_connections.svg';
import { ReactComponent as NoLocationSelectedIcon } from '../../assets/icons/no_location_selected.svg';

export default function Dashboard() {
  /* This whole snippet retrieves the locations under the user using user_id,
  initialises an array of objects { id: ..., name: ...} called locationsData using object deconstruction,
  and maps the array into an array of objects { id: ..., label: ...} called initialItems */ 
  const id = localStorage.getItem('user_id');
  const default_date = new Date('2024-09-01');
  const  { data: locationsData, isSuccess: isLocationsSuccess } = useGetOrgLocationsByUser(id);
  const [initialItems, setInitialItems] = useState([]);

  const [locationSelection, setLocationSelection] = useState([]);
  const [showLiveConnectionCards, setShowLiveConnectionCards] = useState(true); 
  const [liveConnectionStat, setLiveConnectionStat] = useState([]);

  useEffect(() => {
    if (locationsData && locationsData.length > 0) {
      const items = locationsData.map(location => ({ id: location.id, label: location.name }));
      setInitialItems(items);
      setLocationSelection(items); 
    }
  }, [locationsData]);

  const [selectedDateRange, setSelectedDateRange] = useState({from: subDays(new Date(), 7),to: new Date(),});
  const handleDateRangeChange = (newDateRange) => {
    setSelectedDateRange(newDateRange);
  };

  const [accessPointMacList, setAccessPointMacList] = useState([]);
  const [liveConnectionPayload, setLiveConnectionPayload] = useState(null);

  useEffect(() => {
    console.log("locationsData:", locationsData);
    console.log("locationSelection:", locationSelection);

    if (locationSelection.length > 0) {
      // Extract the IDs from the locationSelection objects
      const selectedLocationIds = locationSelection.map(selection => selection.id);
      console.log("selectedLocationIds:", selectedLocationIds);

      const selectedLocations = locationsData.filter(location => 
        selectedLocationIds.includes(location.id)
      );

      console.log("selectedLocations:", selectedLocations);

      const access_point_mac_list = [...new Set(selectedLocations.flatMap(location => 
        location.access_point_mac_list
      ))];

      console.log("access_point_mac_list:", access_point_mac_list);
      setAccessPointMacList(access_point_mac_list);

      // Set live connection payload
      setLiveConnectionPayload({
        timestamp: new Date().getTime(),
        request_type: "live-connections-metrics",
        payload: {
          access_point_mac_list: access_point_mac_list,
        },
      });
    }
  }, [locationSelection, locationsData]);

  const { data: liveConnectionStats, isLoading: isLiveConnectionLoading } = useGetLiveConnectionStats(liveConnectionPayload);

  useEffect(() => {
    if (liveConnectionStats) {
      console.log(liveConnectionStats);
      const liveData = liveConnectionStats.data.data;
      console.log(liveData);
      setLiveConnectionStat([
        {
          title: "Connections",
          value: liveData.live_connections,
          subValue: null,
          badge: false
        },
        {
          title: "New Connections",
          value: liveData.new_connections,
          subValue: liveData.new_connection_percent,
          badge: false
        },
        {
          title: "Returning Connections",
          value: liveData.returning_connections,
          subValue: liveData.returning_connection_percent,
          badge: false,
          separator: true
        },
        {
          title: "Wi-Fi Access Points",
          value: liveData.offline_access_points_count,
          subValue: liveData.online_access_points_count,
          badge: true
        }
      ]);
    }
  }, [liveConnectionStats]);

  useEffect(() => {
    const fetchLiveConnectionStats = () => {
      setLiveConnectionPayload({
        timestamp: new Date().getTime(),
        request_type: "live-connections-metrics",
        payload: {
          access_point_mac_list: accessPointMacList,
        },
      });
    };

    const intervalId = setInterval(fetchLiveConnectionStats, 30000); // 30 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [accessPointMacList]);

  const locationIds = locationsData ? locationsData.map(location => location.id) : [];
  const [metricsDataPayload, setMetricsDataPayload] = useState({
    payload: {
      location_ids: locationIds,
      start_time: selectedDateRange.from.getTime(),
      end_time: selectedDateRange.to.getTime(),
      user_id: id
    }
  });

  const navigate = useNavigate();

  const toggleLiveConnectionCards = () => {
    console.log("Toggle Button Clicked");
    setShowLiveConnectionCards(prevState => !prevState);
  };

  useEffect(() => {
    if (locationSelection.length === 0) {
      setShowLiveConnectionCards(false);
    }
    if (locationSelection.length > 0) {
      setShowLiveConnectionCards(true);
    }
  }, [locationSelection]);

  useEffect(() => {
    const locationIds = locationsData ? locationsData.map(location => location.id) : [];
    setMetricsDataPayload({
      payload: {
        location_ids: locationIds,
        start_time: selectedDateRange.from.getTime(),
        end_time: selectedDateRange.to.getTime(),
        user_id: id
      }
    });
  }, [selectedDateRange, locationsData, id]);

  const { data: metricsData, isLoading: isMetricsLoading } = useGetOrgLocationsMetrics(metricsDataPayload, isLocationsSuccess);

  const fetchDataBasedOnDateRange = (selectedDateRange) => {
    // Your data fetching logic here, using selectedDateRange.from and selectedDateRange.to
    console.log("Fetching data for date range:", selectedDateRange);
  };
  useEffect(() => {
    if (selectedDateRange.from && selectedDateRange.to) {
      fetchDataBasedOnDateRange(selectedDateRange);
    }
  }, [selectedDateRange]);

  var metricTileDataOne = [
    {
      name: "Total Connections", 
      icon: <AllConnectionIcon style={{width: '20px', height: '20px'}} />,
      metric: metricsData?.total_connections ?? 'Loading...',
      updates: `${metricsData?.last_period_tc_change >= 0 ? '+' : ''}${metricsData?.last_period_tc_change ?? '...'}% since last period`
    },
    {
      name: "Returning Connections", 
      icon: <ReturnConnectionIcon style={{width: '20px', height: '20px'}} />,
      metric: metricsData?.returning_connections ?? 'Loading...', 
      updates: `${metricsData?.last_period_rc_change >= 0 ? '+' : ''}${metricsData?.last_period_rc_change ?? '...'}% since last period`
    },
    {
      name: "New Connections", 
      icon: <NewConnectionIcon style={{width: '20px', height: '20px'}} />,
      metric: metricsData?.new_connections ?? 'Loading...', 
      updates: `${metricsData?.last_period_nc_change >= 0 ? '+' : ''}${metricsData?.last_period_nc_change ?? '...'}% since last period`
    },
    {
      name: "Avg. Connection Duration", 
      icon: <AvgTimeConnectionIcon style={{width: '20px', height: '20px'}} />,
      metric: `${metricsData?.average_connection_duration ?? 'Loading...'} mins`,
      updates: `${metricsData?.last_period_acd_change >= 0 ? '+' : ''}${metricsData?.last_period_acd_change ?? '...'}% since last period`
    }
  ];

  var metricTileDataTwo = [
    {
      name: "Avg. Hourly Connections", 
      icon: <AvgHourConnectionIcon style={{width: '20px', height: '20px'}} />,
      metric: metricsData?.average_hourly_connections ?? 'Loading...',
      updates: `${metricsData?.last_period_ahc_change >= 0 ? '+' : ''}${metricsData?.last_period_ahc_change ?? '...'}% since last period`
    },
    {
      name: "Avg. Daily Connections", 
      icon: <AvgDailyConnectionIcon style={{width: '20px', height: '20px'}} />,
      metric: metricsData?.average_daily_connections ?? 'Loading...',
      updates: `${metricsData?.last_period_adc_change >= 0 ? '+' : ''}${metricsData?.last_period_adc_change ?? '...'}% since last period`
    }
  ];

  return (
    <React.Fragment>
      <div style={{ position: "sticky", top: 0, zIndex: 1000, backgroundColor: "white" }}>
        <Header color="white">
          <Flex display="flex" align="center" gap="4">
            <Text className="" size="4" weight="bold">
              Guest Wi-Fi
            </Text>
            <Tooltip content="This product is still in development and so you may encounter issues.">
              <Badge variant="solid" size="1">
                Beta
              </Badge>
            </Tooltip>
            <GearIcon />
          </Flex>
          <Flex display="flex" align="center" gap="4">
            <div>
              <LiveConnectionButton onClick={toggleLiveConnectionCards} />
            </div>
            {/* <div>
              <DeviceGroupingSwitch />
            </div> */}
            <div className="card flex justify-content-center">
              <PrimeReactProvider value={{ unstyled: true, pt: Tailwind }}>
                <MultiSelect
                  value={locationSelection}
                  onChange={(event) => {
                    setLocationSelection(event.value);
                  }}
                  showSelectAll="All Locations"
                  options={initialItems}
                  optionLabel="label"
                  filter
                  placeholder={`Select Locations`}
                  dropdownIcon={<CaretSortIcon />}
                  className="h-8 px-3"
                />
              </PrimeReactProvider>
            </div>
            <div className="flex flex-row gap-2">
              <DatePickerWithRange 
                value={selectedDateRange} 
                onDateChange={handleDateRangeChange}
                disableBeforeDate={metricsData?.disable_before_date ?? default_date}/>
            </div>
            <div>
              <AvatarDropdownMenu
                menu={{
                  title: "null",
                  options: [
                    { label: "Your Profile", onClick: () => navigate("account") },
                    { label: "Org Settings", onClick: () => console.log("Option 2 selected!") },
                    {
                      label: "Support", onClick: () => {
                        const supportEmail = 'support@b-connect.co.uk';
                        const subject = 'Support Request';
                        const body = 'Hello, I need help with...';
                        const mailtoLink = `mailto:${supportEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
                        window.location.href = mailtoLink;
                      }
                    }
                  ],
                }}
                submenu={{
                  title: "Appearance",
                  options: [
                    { label: "Dark", onClick: () => console.log("Suboption 1 selected!") },
                    { label: "Light", onClick: () => console.log("Suboption 2 selected!") },
                    { label: "System", onClick: () => console.log("Suboption 3 selected!") }
                  ]
                }}
              />
            </div>
          </Flex>
        </Header>
        <Separator size="4" orientation="horizontal" />
        <Flex
            style={{
              alignSelf: "stretch",
              position: "sticky",
              top: `0px`,
              zIndex: 1000,
              backgroundColor: "white",
            }}
            display="flex"
            align="start"
            direction="row"
            width="100%"
          >
            <div
              style={{
                maxHeight: showLiveConnectionCards ? "500px" : "0px",
                overflow: "hidden",
                transition: "max-height 0.8s ease-in-out",
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              {showLiveConnectionCards && liveConnectionStat.map((entry, index) => (
                <LiveStatCard
                  key={index}
                  title={entry.title}
                  value={entry.value}
                  subValue={entry.subValue}
                  separator={entry.separator}
                  badge={entry.badge}
                />
              ))}
            </div>
          </Flex>
          <Separator size="4" orientation="horizontal" />
      </div>
      {locationSelection.length === 0 ? (
          <Flex
          display="flex"
          direction="column"
          align="center"
          justify="center"
          height="80vh"
        >
          <NoLocationSelectedIcon style={{ width: '200px', height: '200px' }} />
          <Text
            size="6"
            weight="bold"
            style={{
              color: 'var(--Tokens-Colors-text, #211F27)',
              fontFamily: 'var(--Typography-Font-family-text, Figtree)',
              fontSize: 'var(--Typography-Font-size-5, 20px)',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: 'var(--Typography-Line-height-5, 28px)',
              letterSpacing: 'var(--Typography-Letter-spacing-5, -0.08px)',
            }}
          >
            No Location Selected
          </Text>
          <Text
            size="4"
            style={{
              color: 'var(--Tokens-Colors-text, #211F27)',
              fontFamily: 'var(--Typography-Font-family-text, Figtree)',
              fontSize: 'var(--Typography-Font-size-4, 16px)',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: 'var(--Typography-Line-height-4, 24px)',
              letterSpacing: 'var(--Typography-Letter-spacing-4, -0.08px)',
            }}
          >
            Select a Location to see results
          </Text>
        </Flex>
      ) : (
        <React.Fragment>

          <Flex
            display="flex"
            direction="column"
            align="center"
            gap="5"
            p="5"
          >
            <ChartCard text="Total New and Returning Connections">
              <BarChartWithLine />
            </ChartCard>
            <Flex
              display="flex"
              height="180px"
              width='100%'
              maxWidth="1000px"
              gap="5"
              direction="row"
              justify="center"
            >
              {metricTileDataOne.map((entry, index) => (
                <MetricCard key={index}
                  metricName={entry.name}
                  metricIcon={entry.icon}
                  metric={entry.metric}
                  updates={entry.updates}
                />
              ))}
            </Flex>
            <Flex
              display="flex"
              height="180px"
              width='100%'
              maxWidth="1000px"
              gap="5"
              direction="row"
              justify="center"
            >
              {metricTileDataTwo.map((entry, index) => (
                <MetricCard key={index}
                  metricName={entry.name}
                  metricIcon={entry.icon}
                  metric={entry.metric}
                  updates={entry.updates}
                />
              ))}
            </Flex>
            <ChartCard text="Total Connections">
              <HeatmapChart />
            </ChartCard>
            <ChartCard text="New & Returning Connections by Location">
              <BarChart />
            </ChartCard>
            <ChartCard text="Total Connections by Location">
              <LineChart />
            </ChartCard>
          </Flex>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
