// Import Radix Button under an alias to handle conflict between our button and Radix button
import { Button as RadixButton } from '@radix-ui/themes';
import { Text } from '@radix-ui/themes';
import { EyeOpenIcon } from '@radix-ui/react-icons';

export function LiveConnectionButton({ onClick }) {
    return (
        <RadixButton 
            variant="soft"
            color="gray"
            size="1"
            onClick={onClick} // <-- Pass the onClick prop here
        >
            <EyeOpenIcon />
            <Text size="1" weight="medium">
                Live Connections
            </Text>
        </RadixButton>
    )
}
