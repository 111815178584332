import React from 'react';
import { Line, XAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ComposedChart } from 'recharts';
import { Text, Flex, Separator, Container } from "@radix-ui/themes";
import { DotFilledIcon } from '@radix-ui/react-icons';

const data = [
  {name: "1", shoreditch: 245, marylebone: 320, kensington: 102, harrow: 87, richmond: 520, camberwell: 172, brixton: 312, total: 1758},
  {name: "2", shoreditch: 450, marylebone: 315, kensington: 55, harrow: 72, richmond: 420, camberwell: 182, brixton: 90, total: 1584},
  {name: "3", shoreditch: 512, marylebone: 222, kensington: 384, harrow: 16, richmond: 480, camberwell: 195, brixton: 272, total: 2081},
  {name: "4", shoreditch: 100, marylebone: 500, kensington: 275, harrow: 38, richmond: 600, camberwell: 204, brixton: 322, total: 2039},
  {name: "5", shoreditch: 460, marylebone: 250, kensington: 175, harrow: 93, richmond: 300, camberwell: 105, brixton: 520, total: 1903},
  {name: "6", shoreditch: 325, marylebone: 280, kensington: 190, harrow: 440, richmond: 312, camberwell: 105, brixton: 215, total: 1867},
  {name: "7", shoreditch: 180, marylebone: 525, kensington: 348, harrow: 12, richmond: 300, camberwell: 485, brixton: 115, total: 1965},
  {name: "8", shoreditch: 405, marylebone: 120, kensington: 478, harrow: 223, richmond: 90, camberwell: 314, brixton: 430, total: 2060},
  {name: "9", shoreditch: 475, marylebone: 318, kensington: 290, harrow: 100, richmond: 275, camberwell: 481, brixton: 19, total: 1958},
  {name: "10", shoreditch: 90, marylebone: 450, kensington: 112, harrow: 256, richmond: 305, camberwell: 100, brixton: 222, total: 1535},
  {name: "11", shoreditch: 400, marylebone: 350, kensington: 489, harrow: 250, richmond: 98, camberwell: 190, brixton: 218, total: 1995},
  {name: "12", shoreditch: 502, marylebone: 308, kensington: 250, harrow: 112, richmond: 88, camberwell: 145, brixton: 600, total: 2005},
  {name: "13", shoreditch: 314, marylebone: 205, kensington: 112, harrow: 460, richmond: 298, camberwell: 375, brixton: 158, total: 1922},
  {name: "14", shoreditch: 211, marylebone: 124, kensington: 415, harrow: 98, richmond: 475, camberwell: 160, brixton: 180, total: 1663},
  {name: "15", shoreditch: 105, marylebone: 480, kensington: 235, harrow: 189, richmond: 324, camberwell: 510, brixton: 197, total: 2040},
  {name: "16", shoreditch: 248, marylebone: 94, kensington: 512, harrow: 345, richmond: 215, camberwell: 128, brixton: 402, total: 1944},
  {name: "17", shoreditch: 308, marylebone: 280, kensington: 214, harrow: 120, richmond: 314, camberwell: 100, brixton: 450, total: 1786},
  {name: "18", shoreditch: 420, marylebone: 190, kensington: 475, harrow: 75, richmond: 250, camberwell: 225, brixton: 110, total: 1745},
  {name: "19", shoreditch: 500, marylebone: 312, kensington: 256, harrow: 94, richmond: 125, camberwell: 115, brixton: 298, total: 1700},
  {name: "20", shoreditch: 218, marylebone: 500, kensington: 432, harrow: 300, richmond: 114, camberwell: 76, brixton: 260, total: 1900},
  {name: "21", shoreditch: 190, marylebone: 205, kensington: 175, harrow: 450, richmond: 290, camberwell: 160, brixton: 515, total: 1985},
  {name: "22", shoreditch: 300, marylebone: 124, kensington: 512, harrow: 475, richmond: 250, camberwell: 100, brixton: 233, total: 1994},
  {name: "23", shoreditch: 485, marylebone: 312, kensington: 214, harrow: 140, richmond: 525, camberwell: 50, brixton: 92, total: 1818},
  {name: "24", shoreditch: 105, marylebone: 430, kensington: 315, harrow: 275, richmond: 187, camberwell: 450, brixton: 320, total: 2082}
];


const locations = [
  {location: "shoreditch", color: "#53B9AB"},  // Colors/Teal/8
  {location: "marylebone", color: "#60B3D7"},  // Colors/Semantic/Info/8
  {location: "kensington", color: "#8DB654"},  // Colors/Lime/8
  {location: "harrow", color: "#AA99EC"},      // Colors/Violet/8
  {location: "richmond", color: "#CF91D8"},    // Colors/Plum/8
  {location: "camberwell", color: "#E093B2"},  // Colors/Crimson/8
  {location: "brixton", color: "#E2A336"}      // Colors/Amber/8
];


const CustomLegend = (props) => {
  let { payload } = props;

  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
      gap: '16px', 
      alignSelf: 'stretch' 
    }}>
      {payload.map((entry, index) => (
        index === 7 ? null :
        <Flex key={index} display="flex" align="center" gap="4px">
          <DotFilledIcon style={{ color: entry.color }} />
          <Text size="1" weight="regular" style={{ color: "var(--gray-11)" }}>
            {entry.value.charAt(0).toUpperCase() + entry.value.slice(1)} {/* Capitalize the first letter */}
          </Text>
        </Flex>
      ))}
    </div>
  );
};

const CustomXAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={16}
        y={0} // Adjust y position to fit your requirements
        dy={16} // Offset to ensure proper vertical alignment
        textAnchor="middle"
        fill="var(--gray-11)"
        fontSize="12px"
      >
        {payload.value}
      </text>
    </g>
  );
};

const CustomToolTip = ({ active, payload}) => {

  var total = 0;

  if (active && payload && payload.length) {
    return (
      <Container 
        style={{
          border: "1px solid var(--gray-a6)", 
          borderRadius: "var(--radius-2)", 
          backgroundColor: "var(--color-background )",
          boxShadow: "0px 12px 32px -16px var(--gray-a5)"
        }}
        width="190px"
        maxWidth="480px" >
        <Flex style={{alignSelf: "stretch"}} display="flex" direction="column" align="start">
          <Flex 
            style={{alignSelf: "stretch"}} 
            display="flex" 
            p="2"
            align="center" 
            justify="start" 
            gap="2"
          >
            <Text size="2" weight="medium">29 Sep 2024, 04:00</Text>
          </Flex>
          <Separator orientation="horizontal" size="4" />
          <Flex 
            style={{alignSelf: "stretch"}} 
            display="flex" 
            p="2"
            direction="column"
            justify="center"  
            align="start" 
            gap="2"
          >
            <Flex 
              style={{alignSelf: "stretch"}} 
              display="flex" 
              direction="column"
              align="start" 
              gap="1"
            >
              {/* This is one entry in the tooltip */}
              {payload.map((entry, index) => {
                total += entry.value;
                return (
                  <Flex key={index} style={{alignSelf: "stretch"}} display="flex" justify="between" align="center">
                    <Flex display="flex" align="center" gap="1">
                      <div style={{width: "var(--space-2)", height: "var(--space-2)", borderRadius: "var(--radius-1)", backgroundColor: entry.color}}/>
                      <Text style={{color: "var(--gray-10)"}} size="2" weight="medium" >{entry.name.charAt(0).toUpperCase() + entry.name.slice(1)}</Text>
                    </Flex>
                    <Flex display="flex" align="center" gap="2">
                      <Text style={{color: "var(--gray-10)"}} size="1" weight="regular">14.29% </Text>
                      <Text size="2" weight="medium">{entry.value}</Text>
                    </Flex>
                  </Flex>)
                })
              }
              <Flex style={{alignSelf: "stretch"}} display="flex" justify="between" align="center">
                <Text style={{color: "var(--gray-10)"}} size="2" weight="medium" >Total</Text>
                <Text size="2" weight="medium">{total}</Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    )
  }

  return null;
}

export const LineChart = () => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={968}
        height={316}
        data={data}
      >
        <CartesianGrid 
          stroke="var(--gray-6)" 
          strokeWidth={1} 
          strokeDasharray="1 1"
          verticalCoordinatesGenerator={() => [5, 248, 486, 725, 961]}
          horizontal={true}
        />
        <XAxis 
          dataKey="name" 
          tickLine={false}
          axisLine={{ stroke: "var(--gray-6)", strokeWidth: 1, strokeDasharray: "1 1" }}
          tick={<CustomXAxisTick />}
          tickSize={0}
          interval={0}
          scale="band"
        />
        <Tooltip content={<CustomToolTip/>}/>
        <Legend content={<CustomLegend />} height="16px" />
        {locations.map((entry, index) => (
          <Line 
            key={index} 
            type="natural" 
            dataKey={entry.location}
            stroke={entry.color}
            strokeWidth={2} 
            dot={false} 
          />
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
};