import { Text, Card } from "@radix-ui/themes";

export function ChartCard({ text, children }) {
  return (
    <Card style={{
      display: "flex",
      height: "384px",
      width: "100%",
      maxWidth: "1000px",
      padding: "var(--space-4)",
      flexDirection: "column",
      gap: "var(--space-4)",
      borderRadius: "var(--radius-4)",
      border: "1px solid var(--gray-a6)",
      background: "var(--panel-default)"
      }}
    >
      <Text size="2" weight="medium">
        {text}
      </Text>
      {children}
    </Card>
  )
}