import React from 'react';
import {
  ComposedChart,
  Bar,
  Scatter,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Text, Flex, Separator, Container } from "@radix-ui/themes";
import { DotFilledIcon } from '@radix-ui/react-icons';

const data = [
  { name: 'Shoreditch', new: 150, returning: 500, total: 650, average: 410 },
  { name: 'Marylebone', new: 300, returning: 200, total: 500, average: 320 },
  { name: 'Kensington', new: 420, returning: 180, total: 600, average: 380 },
  { name: 'Harrow', new: 80, returning: 520, total: 600, average: 400 },
  { name: 'Richmond', new: 220, returning: 180, total: 400, average: 290 },
  { name: 'Camberwell', new: 450, returning: 200, total: 650, average: 370 },
  { name: 'Brixton', new: 300, returning: 120, total: 420, average: 340 },
];

const CustomLegend = (props) => {
  let { payload } = props;

  // Sort the payload so that "new" appears before "returning"
  payload = payload.slice().sort((a, b) => {
    if (a.value.toLowerCase() === 'new') return -1;
    if (b.value.toLowerCase() === 'new') return 1;
    return 0;
  });

  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
      gap: '16px', 
      alignSelf: 'stretch' 
    }}>
      {payload.map((entry, index) => (
        index === 3 ? null :
        <Flex key={index} display="flex" align="center" gap="4px">
          <DotFilledIcon style={{ color: entry.color }} />
          <Text size="1" weight="regular" style={{ color: "var(--gray-11)" }}>
            {entry.value.charAt(0).toUpperCase() + entry.value.slice(1)} {/* Capitalize the first letter */}
          </Text>
        </Flex>
      ))}
    </div>
  );
};

const CustomXAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={65}
        y={0} // Adjust y position to fit your requirements
        dy={16} // Offset to ensure proper vertical alignment
        textAnchor="middle"
        fill="var(--gray-11)"
        fontSize="var(--font-size-1)"
      >
        {payload.value}
      </text>
    </g>
  );
};

const CustomToolTip = ({ active, payload}) => {
  // Create a shallow copy of the payload to avoid modifying the original array
  let processedPayload = [...payload];

  // Swap the first and second entries if they both exist
  if (processedPayload.length >= 2) {
    [processedPayload[0], processedPayload[1]] = [processedPayload[1], processedPayload[0]];
  }

  // Capitalize the first letter of the "name" value for each entry
  processedPayload = processedPayload.map(entry => ({
    ...entry,
    name: entry.name.charAt(0).toUpperCase() + entry.name.slice(1)
  }));

  if (active && processedPayload && processedPayload.length) {
    return (
      <Container 
        style={{
          border: "1px solid var(--gray-a6)", 
          borderRadius: "var(--radius-2)", 
          backgroundColor: "var(--color-background )",
          boxShadow: "0px 12px 32px -16px var(--gray-a5)"
        }}
        width="190px"
        maxWidth="480px" >
        <Flex style={{alignSelf: "stretch"}} display="flex" direction="column" align="start">
          <Flex 
            style={{alignSelf: "stretch"}} 
            display="flex" 
            p="2"
            align="center" 
            justify="start" 
            gap="2"
          >
            <Text size="2" weight="medium">29 Sep 2024, 04:00</Text>
          </Flex>
          <Separator orientation="horizontal" size="4" />
          <Flex 
            style={{alignSelf: "stretch"}} 
            display="flex" 
            p="2"
            direction="column"
            justify="center"  
            align="start" 
            gap="2"
          >
            <Flex 
              style={{alignSelf: "stretch"}} 
              display="flex" 
              direction="column"
              align="start" 
              gap="1"
            >
              {/* This is one entry in the tooltip */}
              {processedPayload.map((entry, index) => (
                <Flex key={index} style={{alignSelf: "stretch"}} display="flex" justify="between" align="center">
                  {index === 3 ? 
                    <React.Fragment>
                      <Text style={{color: "var(--gray-10)"}} size="2" weight="medium" >{entry.name}</Text>
                      <Text size="2" weight="medium">{entry.value}</Text>
                    </React.Fragment> :
                  index === 0 || index === 1 ?
                    <React.Fragment>
                      <Flex display="flex" align="center" gap="1">
                        <div style={{width: "var(--space-2)", height: "var(--space-2)", borderRadius: "var(--radius-1)", backgroundColor: entry.color}}/>
                        <Text style={{color: "var(--gray-10)"}} size="2" weight="medium" >{entry.name}</Text>
                      </Flex>
                      <Flex display="flex" align="center" gap="2">
                        <Text style={{color: "var(--gray-10)"}} size="1" weight="regular">14.29% </Text>
                        <Text size="2" weight="medium">{entry.value}</Text>
                      </Flex>
                    </React.Fragment> : null
                  }
                </Flex>))
              }
            </Flex>
          </Flex>
        </Flex>
      </Container>
    )
  }

  return null;
}

export const BarChart = () => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={968}
        height={316}
        data={data}
      >
        <CartesianGrid 
          stroke="var(--gray-6)" 
          strokeWidth={1} 
          strokeDasharray="1 1"
          verticalCoordinatesGenerator={() => [5, 248, 486, 725, 961]}
          horizontal={true}
        />
        <XAxis 
          dataKey="name" 
          tickLine={false}
          axisLine={{ stroke: "var(--gray-6)", strokeWidth: 1, strokeDasharray: "1 1" }}
          tick={<CustomXAxisTick />}
          tickSize={0}
          interval={0}
          scale="band"
        />
        <Tooltip content={<CustomToolTip/>}/>
        <Legend content={<CustomLegend />} height="16px" />
        <Bar dataKey="returning" barSize={92} stackId="a" fill="var(--gray-12)"/>
        <Bar dataKey="new" barSize={92} stackId="a" fill="var(--gray-10)" radius={[3, 3, 0, 0]}/>
        <Scatter dataKey="average" fill="var(--pink-11)" size={10} />
        <Line dataKey="total" stroke="none" strokeOpacity={0} dot={false} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};