// This is the Radix DropdownMenu Primitive. The collection of React components below form the dropdown menu
// Copy paste the import statement and the entire component tree below to use the Radix DropdownMenu Primitive.
// For more information on it, go to https://radix-ui.com/primitives/docs/components/dropdown-menu.

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { ChevronRightIcon, PersonIcon } from '@radix-ui/react-icons';
import { Avatar, Text } from '@radix-ui/themes';
import { useNavigate } from 'react-router-dom';
import { setLogout } from '../../features/auth/authSlice';
import { useDispatch} from 'react-redux';

export function AvatarDropdownMenu ({ menu, submenu }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(setLogout());
    localStorage.clear();
    navigate("/logout");
  };

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button >
          <Avatar size="2" fallback={<PersonIcon />} color="blue"/>
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content size="2" variant="solid" style={{
          display: "flex",
          width: "151px",
          padding: "8px",
          flexDirection: "column",
          alignItems: "flex-start",
          borderRadius: "6px",
          border: "1px solid rgba(32, 0, 80, 0.06)",
          background: "white",
          boxShadow: "0px 12px 32px -16px rgba(16, 0, 72, 0.13), 0px 12px 60px 0px rgba(0, 0, 0, 0.15)"
        }}
        >
          {menu.options.map((option, index) => 
            (<DropdownMenu.Item key={index}>
              <button onClick={option.onClick}>
                <Text>{option.label}</Text>
              </button>
            </DropdownMenu.Item>))
          }

          <DropdownMenu.Separator/>
          <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger asChild>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Text className="font-play" size="2" variant="solid">{submenu.title}</Text>
                <ChevronRightIcon />
              </div>
            </DropdownMenu.SubTrigger>
            <DropdownMenu.Portal>
              <DropdownMenu.SubContent size="2" variant="solid" style={{
                display: "flex",
                width: "151px",
                padding: "8px",
                flexDirection: "column",
                alignItems: "flex-start",
                borderRadius: "6px",
                border: "1px solid rgba(32, 0, 80, 0.06)",
                background: "white",
                boxShadow: "0px 12px 32px -16px rgba(16, 0, 72, 0.13), 0px 12px 60px 0px rgba(0, 0, 0, 0.15)"
              }}>
                {submenu.options.map((option, index) => 
                  (<DropdownMenu.Item key={index}>
                    <button onClick={option.onClick}>
                      <Text className="font-play" size="2" variant="solid">{option.label}</Text>
                    </button>
                  </DropdownMenu.Item>))
                }
              </DropdownMenu.SubContent>
            </DropdownMenu.Portal>
          </DropdownMenu.Sub>

          <DropdownMenu.Separator />

          <DropdownMenu.Item>
            <button onClick={handleLogout}>
              <Text color="red" className="font-play">Log Out</Text>
            </button>
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}