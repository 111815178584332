import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

export const HeatmapChart = () => {
  // Reference to the SVG container
  const svgRef = useRef();

  useEffect(() => {
    // Data Setup
    const days = ["Mon 20", "Tue 21", "Wed 22", "Thu 23", "Fri 24", "Sat 25", "Sun 26"];
    const hours = Array.from({ length: 24 }, (_, i) => i);
    let data = [];

    // Generate random mock data
    days.forEach((day, dayIndex) => {
      hours.forEach((hour) => {
        data.push({ day: dayIndex, hour: hour, value: Math.floor(Math.random() * 50) });
      });
    });

    // Grayscale color scale array using CSS variables
    const grayScaleArray = [
      "var(--gray-1)",
      "var(--gray-2)",
      "var(--gray-3)",
      "var(--gray-4)",
      "var(--gray-5)",
      "var(--gray-6)",
      "var(--gray-7)",
      "var(--gray-8)",
      "var(--gray-9)",
      "var(--gray-10)",
      "var(--gray-11)",
      "var(--gray-12)"
    ];

    // Set up dimensions to match the exact number of boxes with spacing
    const boxWidth = 30.1;
    const boxHeight = 33.7;
    const boxSpacing = 8;

    // Calculate the width and height of the full grid including spacing
    const gridWidth = (boxWidth + boxSpacing) * hours.length - boxSpacing; // 24 hours, considering gaps
    const gridHeight = (boxHeight + boxSpacing) * days.length - boxSpacing; // 7 days, considering gaps

    // Remove existing SVG content (cleanup before re-rendering)
    d3.select(svgRef.current).selectAll("*").remove();

    // Create SVG container
    const svg = d3
      .select(svgRef.current)
      .attr("width", gridWidth + 60) // Add padding for y-axis labels
      .attr("height", gridHeight + 50) // Add space for x-axis labels
      .append("g")
      .attr("transform", `translate(30, 20)`); // Adjust to fit the graph area correctly

    // Create scales
    const xScale = d3.scaleBand().range([0, gridWidth]).domain(hours).paddingInner(0.15).paddingOuter(0);
    const yScale = d3.scaleBand().range([0, gridHeight]).domain(days).paddingInner(0.15).paddingOuter(0);

    // Create a quantize color scale using the grayScaleArray
    const colorScale = d3.scaleQuantize()
      .domain([0, d3.max(data, (d) => d.value)])
      .range(grayScaleArray);

    // Add X Axis (remove line and ticks)
    svg
      .append("g")
      .attr("transform", `translate(32, ${gridHeight - 16})`) // Position the x-axis at the bottom of the graph
      .call(
        d3.axisBottom(xScale)
          .tickSize(0) // Set tick size to 0 to remove ticks
          .tickPadding(10) // Add padding to keep labels visible
          .tickFormat((d) => d.toString().padStart(2, "0"))
      )
      .selectAll("text")
      .style("text-anchor", "middle")
      .style("font-size", "var(--font-size-1)")
      .style("color", "var(--gray-11)")
      .style("font-family", "var(--default-font-family)");

    svg.selectAll(".domain").remove(); // Remove x-axis domain line

    // Add Y Axis (remove line and ticks)
    svg
      .append("g")
      .attr("transform", `translate(27, -20)`) // Position the y-axis
      .call(
        d3.axisLeft(yScale)
          .tickSize(0) // Set tick size to 0 to remove ticks
          .tickPadding(10) // Add padding to keep labels visible
      )
      .selectAll("text")
      .style("font-size", "var(--font-size-1)")
      .style("color", "var(--gray-11)")
      .style("font-family", "var(--default-font-family)");

    svg.selectAll(".domain").remove(); // Remove y-axis domain line

    // Add the heatmap rectangles with borders for all colors
    svg
      .selectAll()
      .data(data, (d) => d.day + ":" + d.hour)
      .enter()
      .append("g")
      .attr("transform", `translate(32, -20)`)
      .append("rect")
      .attr("x", (d) => xScale(d.hour))
      .attr("y", (d) => yScale(days[d.day]))
      .attr("width", boxWidth)
      .attr("height", boxHeight)
      .attr("rx", 6) // Rounded corners for aesthetics
      .attr("fill", (d) => colorScale(d.value))
      .style("stroke", "var(--gray-6)") // Border for all colors
      .style("stroke-width", "1px")
      .on("mouseover", function (event, d) {
        showTooltip(event, {
          day: days[d.day],
          hour: d.hour,
          value: d.value,
        });
        d3.select(this).attr("stroke", "#fff").attr("stroke-width", 2); // Highlight border on hover
      })
      .on("mouseout", function () {
        hideTooltip();
        d3.select(this).style("stroke", "var(--gray-6)").style("stroke-width", "1px"); // Reset border when not hovering
      });

    // Create Tooltip (using inline styling and nested elements)
    const tooltip = d3
      .select("body")
      .append("div")
      .style("position", "absolute")
      .style("width", "200px")
      .style("height", "73px")
      .style("background", "white")
      .style("box-shadow", "0px 12px 60px rgba(0, 0, 0, 0.15)")
      .style("border-radius", "4px")
      .style("overflow", "hidden")
      .style("border", "1px solid rgba(14, 0, 59, 0.15)")
      .style("display", "flex")
      .style("flex-direction", "column")
      .style("justify-content", "flex-start")
      .style("align-items", "flex-start")
      .style("pointer-events", "none")
      .style("opacity", 0)
      .style("z-index", 1000);

    const tooltipContainer = tooltip.append("div")
      .style("height", "73px")
      .style("display", "flex")
      .style("flex-direction", "column")
      .style("justify-content", "flex-start")
      .style("align-items", "flex-start")
      .style("align-self", "stretch")
      .style("border-radius", "4px");

    const textContainer = tooltipContainer.append("div")
      .style("padding", "8px 12px")
      .style("display", "flex")
      .style("justify-content", "center")
      .style("align-items", "center")
      .style("gap", "8px")
      .style("align-self", "stretch");

    textContainer.append("div")
      .style("flex", "1 1 0")
      .style("color", "#211F27")
      .style("font-size", "14px")
      .style("font-family", "Figtree")
      .style("font-weight", "500")
      .style("line-height", "20px")
      .style("word-wrap", "break-word")
      .attr("class", "tooltip-date");

    tooltipContainer.append("div")
      .style("align-self", "stretch")
      .style("height", "1px")
      .style("background", "rgba(14, 0, 59, 0.15)")
      .style("display", "flex")
      .style("justify-content", "flex-start")
      .style("align-items", "center");

    const valueContainer = tooltipContainer.append("div")
      .style("padding", "8px 12px")
      .style("display", "flex")
      .style("flex-direction", "column")
      .style("justify-content", "center")
      .style("align-items", "flex-start")
      .style("gap", "8px")
      .style("height", "36px")
      .style("align-self", "stretch");

    const valueRow = valueContainer.append("div")
      .style("display", "flex")
      .style("justify-content", "space-between")
      .style("align-items", "center")
      .style("align-self", "stretch");

    valueRow.append("div")
      .style("color", "#65636E")
      .style("font-size", "14px")
      .style("font-family", "Figtree")
      .style("font-weight", "500")
      .style("line-height", "20px")
      .style("word-wrap", "break-word")
      .text("Total");

    valueRow.append("div")
      .style("color", "#211F27")
      .style("font-size", "14px")
      .style("font-family", "Figtree")
      .style("font-weight", "500")
      .style("line-height", "20px")
      .style("word-wrap", "break-word")
      .attr("class", "tooltip-value");

    function showTooltip(event, data) {
      tooltip.select(".tooltip-date").text(`${data.day}, ${data.hour.toString().padStart(2, '0')}:00`);
      tooltip.select(".tooltip-value").text(data.value);
      tooltip
        .style("opacity", 1)
        .style("left", event.pageX + 10 + "px")
        .style("top", event.pageY - 28 + "px")
        .style("display", "block");
    }

    function hideTooltip() {
      tooltip.style("opacity", 0).style("display", "none");
    }

    return () => {
      d3.select(svgRef.current).selectAll("*").remove();
      tooltip.remove();
    };
  }, []);

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <svg ref={svgRef} style={{ position: "absolute", top: 0, right: 0, margin: 0, padding: 0 }}></svg>
    </div>
  );
};

