import React from 'react'

export function Header({children}) {
  return (
    <header style={{
      display: "flex",
      padding: "16px",
      justifyContent: "space-between",
      alignItems: "center",
      alignSelf: "stretch",
    }}>
      {children}
    </header>
  )
}