import React from 'react';
import { Text, Flex, Badge } from "@radix-ui/themes";
import { InfoCircledIcon } from '@radix-ui/react-icons';
import { sub } from 'date-fns';

export function LiveStatCard({ title, value, subValue, badge }) {

  var conditionalView

  if (badge === true || value === null) {
    conditionalView = (
      <Flex 
        style={{flex: "1 0 0"}} 
        display="flex" 
        align="center" 
        gap="2"
      >
        <Badge variant="soft" color="red" size="1">
          <InfoCircledIcon style={{ width: '12px', height: '12px', color: "#C40006" }} />
          <Text size="1" weight="medium">
            {value} Offline
          </Text>
        </Badge>
        <Badge variant="soft" color="green" size="1">
          <Text size="1" weight="medium">
            {subValue} Online
          </Text>
        </Badge>
      </Flex>
    )
  } else {
    if (subValue === null) {
      conditionalView = (
        <Text style={{color: "var(--accent-11)"}} size="6" weight="bold">{value}</Text>
      )
    } else {
      conditionalView = (
        <Flex display="flex" align="center" gap="2">
          <Text style={{color: "var(--accent-11)"}} size="6" weight="bold">{value}</Text>
          <Text style={{color: "var(--accent-11)"}} size="2" weight="regular">{subValue}</Text>
        </Flex>
      )
    }
  }

  return (
    <React.Fragment>
      <Flex style={{
        display: "flex",
        padding: "var(--space-4) var(--space-5)",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "var(--space-4)",
        flex: "1 0 0",
        alignSelf: "stretch",
        border: "1px solid var(--gray-a6)",
        }}
      >
        <Flex 
          style={{alignSelf: "stretch"}} 
          display="flex" 
          align="center" 
          gap="2"
        >
          <Badge variant="soft" size="1">Live</Badge>
          <Text style={{color: "var(--accent-a11)"}} size="2" weight="medium" >{title}</Text>
        </Flex>
        {conditionalView}
      </Flex>
    </React.Fragment>
  );
};