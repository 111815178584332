import { useQuery } from '@tanstack/react-query';
import { attemptGetOrgLocationsByUser } from '../../api/OrgApi';
import { attemptGetOrgLocationsMetrics } from '../../api/OrgApi';
import { toast } from 'react-toastify';

const fetchOrgLocationsByUser = async (userId) => {
    return attemptGetOrgLocationsByUser(userId).then((res) => res.data['data']['locations']);
};

const fetchOrgLocationsMetrics = async (data) => {
    return attemptGetOrgLocationsMetrics(data).then((res) => res.data['data']);
};

export const useGetOrgLocationsByUser = (userId) => {
    return useQuery({
        queryKey: ['org-locations-by-user', userId],
        queryFn: () => fetchOrgLocationsByUser(userId),
        onError: (error) => {
            toast.error(`Error fetching locations: ${error.message}`);
        },
    });
};

export const useGetOrgLocationsMetrics = (data, enabled) => {
    return useQuery({
        queryKey: ['org-locations-metrics', data],
        queryFn: () => fetchOrgLocationsMetrics(data),
        enabled,
        onError: (error) => {
            toast.error(`Error fetching locations metrics: ${error.message}`);
        },
    });
}