import React from 'react';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Text, Flex, Separator, Container } from "@radix-ui/themes";
import { DotFilledIcon } from '@radix-ui/react-icons';

const data = [
  { name: '1', new: 0, returning: 0, total: 0, average: 210 },
  { name: '2', new: 0, returning: 0, total: 0, average: 270 },
  { name: '3', new: 0, returning: 0, total: 0, average: 350 },
  { name: '4', new: 150, returning: 500, total: 650, average: 410 },
  { name: '5', new: 300, returning: 200, total: 500, average: 320 },
  { name: '6', new: 420, returning: 180, total: 600, average: 380 },
  { name: '7', new: 80, returning: 520, total: 600, average: 400 },
  { name: '8', new: 220, returning: 180, total: 400, average: 290 },
  { name: '9', new: 450, returning: 200, total: 650, average: 370 },
  { name: '10', new: 300, returning: 120, total: 420, average: 340 },
  { name: '11', new: 180, returning: 470, total: 650, average: 390 },
  { name: '12', new: 400, returning: 100, total: 500, average: 360 },
  { name: '13', new: 230, returning: 340, total: 570, average: 380 },
  { name: '14', new: 90, returning: 410, total: 500, average: 310 },
  { name: '15', new: 320, returning: 180, total: 500, average: 350 },
  { name: '16', new: 150, returning: 450, total: 600, average: 390 },
  { name: '17', new: 380, returning: 120, total: 500, average: 370 },
  { name: '18', new: 270, returning: 330, total: 600, average: 400 },
  { name: '19', new: 200, returning: 200, total: 400, average: 300 },
  { name: '20', new: 450, returning: 150, total: 600, average: 350 },
  { name: '21', new: 170, returning: 350, total: 520, average: 330 },
  { name: '22', new: 0, returning: 0, total: 0, average: 300 },
  { name: '23', new: 0, returning: 0, total: 0, average: 290 },
  { name: '24', new: 0, returning: 0, total: 0, average: 260 }
];

const CustomLegend = (props) => {
  let { payload } = props;

  // Sort the payload so that "new" appears before "returning"
  payload = payload.slice().sort((a, b) => {
    if (a.value.toLowerCase() === 'new') return -1;
    if (b.value.toLowerCase() === 'new') return 1;
    return 0;
  });

  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
      gap: '16px', 
      alignSelf: 'stretch' 
    }}>
      {payload.map((entry, index) => (
        index === 2 ? null :
        <Flex key={index} display="flex" align="center" gap="4px">
          <DotFilledIcon style={{ color: entry.color }} />
          <Text size="1" weight="regular" style={{ color: "var(--gray-11)" }}>
            {entry.value.charAt(0).toUpperCase() + entry.value.slice(1)} {/* Capitalize the first letter */}
          </Text>
        </Flex>
      ))}
    </div>
  );
};

const CustomXAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={16}
        y={0} // Adjust y position to fit your requirements
        dy={16} // Offset to ensure proper vertical alignment
        textAnchor="middle"
        fill="var(--gray-11)"
        fontSize="var(--font-size-1)"
      >
        {payload.value}
      </text>
    </g>
  );
};

const CustomToolTip = ({ active, payload}) => {
  // Create a shallow copy of the payload to avoid modifying the original array
  let processedPayload = [...payload];

  // Swap the first and second entries if they both exist
  if (processedPayload.length >= 2) {
    [processedPayload[0], processedPayload[1]] = [processedPayload[1], processedPayload[0]];
  }

  // Capitalize the first letter of the "name" value for each entry
  processedPayload = processedPayload.map(entry => ({
    ...entry,
    name: entry.name.charAt(0).toUpperCase() + entry.name.slice(1)
  }));

  if (active && processedPayload && processedPayload.length) {
    return (
      <Container 
        style={{
          border: "1px solid var(--gray-a6)", 
          borderRadius: "var(--radius-2)", 
          backgroundColor: "var(--color-background )",
          boxShadow: "0px 12px 32px -16px var(--gray-a5)"
        }}
        width="190px"
        maxWidth="480px" >
        <Flex style={{alignSelf: "stretch"}} display="flex" direction="column" align="start">
          <Flex 
            style={{alignSelf: "stretch"}} 
            display="flex" 
            p="2"
            align="center" 
            justify="start" 
            gap="2"
          >
            <Text size="2" weight="medium">29 Sep 2024, 04:00</Text>
          </Flex>
          <Separator orientation="horizontal" size="4" />
          <Flex 
            style={{alignSelf: "stretch"}} 
            display="flex" 
            p="2"
            direction="column"
            justify="center"  
            align="start" 
            gap="2"
          >
            <Flex 
              style={{alignSelf: "stretch"}} 
              display="flex" 
              direction="column"
              align="start" 
              gap="1"
            >
              {/* This is one entry in the tooltip */}
              {processedPayload.map((entry, index) => (
                <Flex key={index} style={{alignSelf: "stretch"}} display="flex" justify="between" align="center">
                  {index === 0 || index === 1 ? 
                    <React.Fragment>
                      <Flex display="flex" align="center" gap="1">
                        <div style={{width: "var(--space-2)", height: "var(--space-2)", borderRadius: "var(--radius-1)", backgroundColor: entry.color}}/>
                        <Text style={{color: "var(--gray-10)"}} size="2" weight="medium" >{entry.name}</Text>
                      </Flex>
                      <Flex display="flex" align="center" gap="2">
                        <Text style={{color: "var(--gray-10)"}} size="1" weight="regular">14.29% </Text>
                        <Text size="2" weight="medium">{entry.value}</Text>
                      </Flex>
                    </React.Fragment> : 
                    <React.Fragment>
                      <Text style={{color: "var(--gray-10)"}} size="2" weight="medium" >{entry.name}</Text>
                      <Text size="2" weight="medium">{entry.value}</Text>
                    </React.Fragment>
                  }
                </Flex>))
              }
            </Flex>
          </Flex>
        </Flex>
      </Container>
    )
  }

  return null;
}

export const BarChartWithLine = () => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={968}
        height={316}
        data={data}
      >
        <CartesianGrid 
          stroke="var(--gray-6)" 
          strokeWidth={1} 
          strokeDasharray="1 1"
          verticalCoordinatesGenerator={() => [5, 248, 486, 725, 961]}
          horizontal={true}
        />
        <XAxis 
          dataKey="name" 
          tickLine={false}
          axisLine={{ stroke: "var(--gray-6)", strokeWidth: 1, strokeDasharray: "1 1" }}
          tick={<CustomXAxisTick />}
          tickSize={0}
          interval={0}
          scale="band"
        />
        <Tooltip content={<CustomToolTip/>}/>
        <Legend content={<CustomLegend />} height="16px" />
        <Bar dataKey="returning" barSize={32} stackId="a" fill="var(--gray-12)"/>
        <Bar dataKey="new" barSize={32} stackId="a" fill="var(--gray-10)"/>
        <Line dataKey="total" stroke="none" strokeOpacity={0} dot={false} />
        <Line type="natural" strokeDasharray="5 2" dataKey="average" stroke="var(--pink-9)" dot={false} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};