import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const useAuth = () => {
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  const user_role = localStorage.getItem("user_role");
  if (accessToken || refreshToken || user_role) {
    return {
      auth : true,
      role : user_role
    };
  } else {
    return {
      auth : false,
      role : null
    };
  }
};

const ProtectedRoute = ({ userRole }) => {
  const { auth, role } = useAuth();

  if (!auth) {
    return <Navigate to="/login" />;
  }

  if (userRole && userRole !== role) {
    return <Navigate to="/logout" />;
  }

  return <Outlet />;
};
export default ProtectedRoute;
